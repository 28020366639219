import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { styled } from "styled-components";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SubHeading, Text } from "../../utils/typography.js";
import CustomButton from "../atoms/CustomButton.js";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media ${queries.laptop} {
    align-items: start;
    flex-direction: row;
    gap: 35px;
  }
`;

const Number = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  padding: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props?.$bgc ? props.$bgc : "white")};
  color: ${colors.brownlight};
  box-shadow: 2px 2px 5px 2px ${colors.shadowbrown};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${queries.laptop} {
    align-items: start;
    text-align: left;
  }
`;

const ResponsabilitiesWrapper = styled.ul`
  padding-left: 15px;
  text-align: left;
`;

const Responsability = styled.li`
  color: ${colors.brown};
  opacity: 0.85;
`;

const CompanyData = (props) => {
  const { t } = useTranslation();
  const data = props;
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive((prev) => !prev);
  };

  return (
    <Zoom triggerOnce delay={data.delay}>
      <Wrapper>
        <Number $bgc={data.$bgc}>
          <SubHeading text={data.id} />
        </Number>
        <DataWrapper>
          <Text size="16px">{data.title}</Text>
          <Text $decoration="italic">{data.period}</Text>
          <Text $weight="bold">{data.position}</Text>
          <CustomButton
            dropdown
            text={t("exp.description")}
            onClick={handleToggle}
            active={active}
          />
          <ResponsabilitiesWrapper
            style={{
              opacity: active ? 1 : 0,
              height: active ? "100%" : 0,
              transition: "all 0.2s ease-in",
            }}
          >
            {data.responsabilities.map((res, i) => (
              <Responsability key={i}>{res}</Responsability>
            ))}
          </ResponsabilitiesWrapper>
        </DataWrapper>
      </Wrapper>
    </Zoom>
  );
};

export default CompanyData;
