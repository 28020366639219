import React from "react";
import { styled } from "styled-components";
import { Zoom } from "react-awesome-reveal";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { colors } from "../../utils/colors.js";

const ButtonWrapper = styled.button`
  position: relative;
  padding: 8px 16px;
  background-color: ${(props) =>
    props.option === "primary"
      ? colors.brown
      : props.option === "secondary"
      ? colors.brownlight
      : "white"};
  color: ${colors.white};
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: 2px 2px 5px 2px ${colors.shadowbrown};
  text-transform: capitalize;
  transition: 0.3s;
  min-width: 155px;

  &:hover {
    background-color: ${(props) =>
      props.option === "primary"
        ? colors.browndarker
        : props.option === "secondary"
        ? colors.beige
        : "white"};
  }
`;

const DropdownWrapper = styled.button`
  position: relative;
  padding: 10px 0px 0px;
  background-color: transparent;
  color: ${colors.brown};
  border: none;
  outline: none;
  transition: 0.3s;
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const DownloadButtonWrapper = styled.a`
  position: relative;
  padding: 8px 16px;
  background-color: ${(props) =>
    props.option === "primary"
      ? colors.brown
      : props.option === "secondary"
      ? colors.brownlight
      : "white"};
  color: ${colors.white};
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: 2px 2px 5px 2px ${colors.shadowbrown};
  text-transform: capitalize;
  transition: 0.3s;
  min-width: 155px;

  &:hover {
    background-color: ${(props) =>
      props.option === "primary"
        ? colors.browndarker
        : props.option === "secondary"
        ? colors.beige
        : "white"};
  }
`;

const CustomButton = ({
  text,
  onClick,
  option,
  delay,
  active,
  dropdown,
  download,
  href,
  target,
  props,
}) => {
  return (
    <Zoom triggerOnce delay={delay || 400}>
      {!download && !href && !dropdown ? (
        <ButtonWrapper
          option={option ? option : "primary"}
          onClick={onClick}
          props={props}
        >
          {text}
        </ButtonWrapper>
      ) : dropdown ? (
        <DropdownWrapper onClick={onClick} props={props}>
          <DropdownContent>
            {text}
            {!active ? <FaChevronDown size={10} /> : <FaChevronUp size={10} />}
          </DropdownContent>
        </DropdownWrapper>
      ) : (
        <DownloadButtonWrapper
          option={option ? option : "primary"}
          download={download}
          href={href}
          target={target || "_blank"}
          rel="noopener noreferrer"
          props={props}
        >
          {text}
        </DownloadButtonWrapper>
      )}
    </Zoom>
  );
};

export default CustomButton;
