import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade, Slide } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle, Text } from "../../utils/typography.js";
import CompanyData from "../modules/CompanyData.js";
import MonsteraImg from "../../img/exp_monstera.png";
import LeafImg from "../../img/exp_leaf.png";
import TapeImg from "../../img/exp_tape.png";

const ExpWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 15px 500px;
  background-color: ${colors.beige};
  overflow: hidden;
  z-index: 3;

  @media ${queries.tablet} {
    min-height: 100vh;
  }

  @media ${queries.laptop} {
    padding: 80px 15px;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  bottom: 120px;
  left: 20px;
  display: block;
  width: calc(100% - 40px);
  height: 300px;
  z-index: 4;

  @media ${queries.tablet} {
    bottom: 20px;
    left: 20px;
    height: 450px;
    width: 50%;
  }

  @media ${queries.laptop} {
    height: 700px;
    width: 600px;
  }
`;

const MonsteraImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin: 0 auto 0 0;

  @media ${queries.tablet} {
    max-height: 450px;
  }

  @media ${queries.laptop} {
    max-height: 700px;
  }
`;

const LeafWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: -40px;
  width: 50%;
  z-index: 4;

  @media ${queries.laptop} {
    display: none;
  }

  @media ${queries.desktopS} {
    display: block;
    width: auto;
  }
`;

const LeafImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 180px;
  margin: 0 0 0 auto;
  transform: rotate(-20deg);

  @media ${queries.desktopS} {
    max-height: 250px;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
`;

const BackgroundWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 15px;
  background-color: ${colors.beigelight};
  text-align: center;
  transform: rotate(-1deg);
  box-shadow: 5px 5px 10px 2px ${colors.shadow};
  transition-duration: 0.3s;
  z-index: 3;

  & > div {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media ${queries.tablet} {
    height: 450px;
    max-width: 600px;
    margin-bottom: 100px;
  }

  @media ${queries.laptop} {
    margin-bottom: 0px;
  }
`;

const TapeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
`;

const TapeImage = styled.img`
  position: absolute;
  top: 0px;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100%;

  @media ${queries.laptop} {
    align-items: start;
  }
`;

const Exp = () => {
  const { t } = useTranslation();

  const expData = [
    {
      id: t("exp.companies.1.id"),
      title: t("exp.companies.1.name"),
      period: t("exp.companies.1.period"),
      position: t("exp.companies.1.position"),
      responsabilities: [
        t("exp.companies.1.responsabilities.1"),
        t("exp.companies.1.responsabilities.2"),
      ],
      $bgc: colors.mintlight,
      delay: 800,
    },
    {
      id: t("exp.companies.2.id"),
      title: t("exp.companies.2.name"),
      period: t("exp.companies.2.period"),
      position: t("exp.companies.2.position"),
      responsabilities: [
        t("exp.companies.2.responsabilities.1"),
        t("exp.companies.2.responsabilities.2"),
        t("exp.companies.2.responsabilities.3"),
        t("exp.companies.2.responsabilities.4"),
        t("exp.companies.2.responsabilities.5"),
        t("exp.companies.2.responsabilities.6"),
        t("exp.companies.2.responsabilities.7"),
      ],
      $bgc: colors.mint,
      delay: 1000,
    },
    {
      id: t("exp.companies.3.id"),
      title: t("exp.companies.3.name"),
      period: t("exp.companies.3.period"),
      position: t("exp.companies.3.position"),
      responsabilities: [
        t("exp.companies.3.responsabilities.1"),
        t("exp.companies.3.responsabilities.2"),
        t("exp.companies.3.responsabilities.3"),
        t("exp.companies.3.responsabilities.4"),
        t("exp.companies.3.responsabilities.5"),
      ],
      $bgc: colors.mintlight,
      delay: 1200,
    },
  ];

  return (
    <ExpWrapper id={t("menu.exp")}>
      <MonsteraWrapper>
        <Slide triggerOnce direction="up" delay={800}>
          <MonsteraImage src={MonsteraImg} alt={t("exp.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
      <LeafWrapper>
        <Slide triggerOnce direction="right" delay={1800}>
          <LeafImage src={LeafImg} alt={t("exp.leafAlt")} />
        </Slide>
      </LeafWrapper>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <TitleWrapper>
              <TapeWrapper>
                <Fade triggerOnce delay={1500}>
                  <TapeImage src={TapeImg} alt={t("exp.tapeAlt")} />
                </Fade>
              </TapeWrapper>
              <Slide triggerOnce direction="left" delay={400}>
                <BackgroundWrapper>
                  <Fade triggerOnce delay={1000}>
                    <SectionTitle text={t("exp.title")} $index={3} />
                  </Fade>
                  <Fade triggerOnce delay={1200}>
                    <Text $index={3}>{t("exp.subtitle")}</Text>
                  </Fade>
                </BackgroundWrapper>
              </Slide>
            </TitleWrapper>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <CompanyWrapper>
              {expData.map((company) => (
                <CompanyData key={company.id} {...company} />
              ))}
            </CompanyWrapper>
          </Col>
        </Row>
      </Container>
    </ExpWrapper>
  );
};

export default Exp;
