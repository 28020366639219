import { useTranslation } from "react-i18next";
import { colors } from "../../utils/colors";
import CookieConsent from "react-cookie-consent";

const Cookies = () => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      cookieName="myAwesomeCookieName"
      buttonText={t("cookies.confirm")}
      style={{
        background: colors.white,
        color: colors.mintdarker,
        fontFamily: "Caudex",
        fontSize: "15px",
        padding: "20px",
        fontWeight: "bold"
      }}
      buttonStyle={{
        background: colors.mint,
        color: colors.mintdarker,
        fontSize: "13px",
        borderRadius: "3px",
        fontWeight: "bold"
      }}
      overlay
    >
      {t("cookies.alert")}
    </CookieConsent>
  );
};

export default Cookies;