import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle } from "../../utils/typography.js";
import MonsteraImg from "../../img/skills_monsteraTop.png";
import SkillTile from "../modules/SkillTile.js";

const SkillsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 280px 15px;
  background-color: ${colors.beigelight};
  overflow: hidden;
  z-index: 3;

  h2 {
    margin-bottom: 80px;
  }

  @media ${queries.tablet} {
    min-height: 100vh;
    padding: 350px 15px;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  display: block;
  width: 200px;

  &.top {
    top: 15px;
    right: 15px;
  }

  &.bottom {
    bottom: 15px;
    left: 15px;
    transform: rotate(180deg);
  }

  @media ${queries.tablet} {
    width: 400px;
  }
`;

const MonsteraImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin: auto;

  @media ${queries.tablet} {
    max-height: 600px;
  }
`;

const SkillsDataWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;

  @media ${queries.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${queries.laptopL} {
    flex-wrap: nowrap;
  }
`;

const Skills = () => {
  const { t } = useTranslation();

  const skillsData = [
    {
      id: 1,
      title: t("skills.hard.title"),
      data: t("skills.hard.data"),
      $bgc: colors.mint,
      $rotation: "-2deg",
      delay: 400,
    },
    {
      id: 2,
      title: t("skills.soft.title"),
      data: t("skills.soft.data"),
      $bgc: colors.mintlight,
      $rotation: "1deg",
      delay: 800,
    },
    {
      id: 3,
      title: t("skills.languages.title"),
      data: [
        t("skills.languages.pl"),
        t("skills.languages.en"),
        t("skills.languages.es"),
        t("skills.languages.de"),
      ],
      $bgc: colors.white,
      $rotation: "-1deg",
      delay: 1000,
    },
  ];

  return (
    <SkillsWrapper id={t("menu.skills")}>
      <MonsteraWrapper className="top">
        <Slide triggerOnce direction="left" delay={200}>
          <MonsteraImage src={MonsteraImg} alt={t("skills.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <Zoom triggerOnce delay={400}>
              <SectionTitle text={t("skills.title")} />
            </Zoom>
          </Col>
          <Col xs={12}>
            <SkillsDataWrapper>
              {skillsData.map((skill) => (
                <SkillTile key={skill.id} {...skill} />
              ))}
            </SkillsDataWrapper>
          </Col>
        </Row>
      </Container>
      <MonsteraWrapper className="bottom">
        <Slide triggerOnce direction="left" delay={200}>
          <MonsteraImage src={MonsteraImg} alt={t("skills.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
    </SkillsWrapper>
  );
};

export default Skills;
