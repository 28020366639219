import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import NotFound from "./404";

function Page({ lang, handleLanguageChange }) {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Home lang={lang} handleLanguageChange={handleLanguageChange} />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Page;
